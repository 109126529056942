export function checkBookingFunctionData(functionData, id) {
   let url = null;
   let isBookable = false;
   let isCall = false;
   let isEnquiry = false;
   let caption, content;
   const urlRegex =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi;

   if (functionData.length > 0) {
      functionData.some(data => {
         if (data.type == 3) {
            isBookable = true;
            url = urlRegex.test(data.content) ? id : data.content;
         } else if (data.type == 2) {
            isEnquiry = true;
            caption = data.caption;
         } else if (data.type == 1) {
            isCall = true;
            content = data.content;
         }
      });
   }

   return { url, isBookable, isCall, isEnquiry, caption, content };
}

export function getSportById(allSports = [], id) {
   var foundsport = {};

   allSports.some(sport => {
      if (sport.sportId == id) {
         foundsport = sport;
         return true;
      }
   });

   return foundsport;
}

export function getDurationasString(duration) {
   let hrs = parseInt(duration / 60) > 0 ? parseInt(duration / 60) + " hr " : "";

   let mins = duration % 60 > 0 ? (duration % 60) + " mins" : "";

   let dText = hrs + mins;

   return dText;
}

export function getSportsName(allSports, sportsId) {
   try {
      // console.log({ allSports });
      var sportsNameArray = [];

      sportsId.forEach(id => {
         allSports.some(sport => {
            if (sport.sportId == id) {
               sportsNameArray.push(sport.name.split(" ").join("_"));
               return true;
            }
         });
      });

      return sportsNameArray;
   } catch (err) {
      console.log("in sports Name---->>>>>", err);
      return [];
   }
}

export const areaUrl = name => {
   if (!name) return "";
   let area = name.toLowerCase();
   area = area.split(",")[0];
   area = area.replace(/ - /g, "-");
   area = area.split(" ").filter(x => x.length > 0);
   area = area.join("-");
   area = area.replace(/(\.|\"|\`|\'|\/|\#|\s)/g, "");
   return area;
};

export function getPrice(tickets, selected, forHowMany) {
   var price = 0;
   var index = 0;
   tickets.forEach(ticket => {
      if (forHowMany == "oneTicket") {
         if (ticket.type == 1) {
            index = ticket.dates.indexOf(selected.selectedDate);
            price = price + parseFloat(ticket.price[index]);
         } else if (ticket.type == 2) {
            index = ticket.slots.indexOf(selected.selectedTime);
            price = price + parseFloat(ticket.price[index]);
         } else {
            price = price + parseFloat(ticket.price[0]);
         }
      } else if (ticket.count > 0) {
         var discount = "";
         if (ticket.sDisc) {
            discount = JSON.parse(ticket.sDisc);
         }

         if (ticket.type == 1) {
            index = ticket.dates.indexOf(selected.selectedDate);
            price = price + findApplicablePrice(ticket.price[index], ticket.count, discount);
         } else if (ticket.type == 2) {
            index = ticket.slots.indexOf(selected.selectedTime);
            price = price + findApplicablePrice(ticket.price[index], ticket.count, discount);
         } else {
            price = price + findApplicablePrice(ticket.price[0], ticket.count, discount);
         }
      }
   });
   return price;
}

export function checkMobile(value) {
   const numRegex = /^(\+\d{1,3}[- ]?)?\d{8,12}$/;
   return value && numRegex.test(value.trim());
}

export function getPriceObj(data) {
   let gross = 0;
   let payable = 0;
   let paid = 0;
   let discount = 0;
   let insureFee = 0;
   let totalPayable = 0;
   let creditAmount = 0;
   let totalFee = 0;
   let convFee = 0;

   data.forEach(category => {
      gross = gross + parseFloat(category.total);
      paid = paid + parseFloat(category.advanceAmount);
      payable = payable + parseFloat(category.payable);
      discount = discount + parseFloat(category.discAmount);
      insureFee = insureFee + parseFloat(category.insureFee);
      totalPayable = totalPayable + parseFloat(category.totalPayable);
      creditAmount = creditAmount + parseFloat(category.creditAmount);
      totalFee = totalFee + parseFloat(category.totalFee);
      convFee = convFee + parseFloat(category.convFee);
   });
   return {
      gross,
      payable,
      paid,
      discount,
      insureFee,
      totalPayable,
      creditAmount,
      totalFee,
      convFee
   };
}

export const debounce = (func, delay) => {
   let timer;
   return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
         timer = null;
         func.apply(context, args);
      }, 800);
   };
};

export const getActivityTags = details => {
   let activityTag = [];
   const tagsLabel = {
      1: "BYOE",
      2: "Cost Shared",
      4: "Vaccinated Players Preferred"
   };
   const tagsLabelIcon = {
      1: "BYOE",
      2: "Split-amount"
   };
   if (details.type === 1) {
      let data = {
         title: `${details.currencyTxt} ${details.price.toFixed(2)} / per player`,
         icon: "pay+and+join+1",
         cssStyle: "col-span-2 md:col-span-1 justify-center "
      };
      activityTag.push(data);
   }

   if (details?.tags?.length > 0) {
      details.tags.map(tag => {
         if (tag.value === true) {
            let data = {
               title: tagsLabel[tag.tagId] || "",
               icon: tagsLabelIcon[tag?.tagId] || "",
               cssStyle: "col-span-1 justify-center "
            };
            activityTag.push(data);
         }
      });
   }
   if (details.activityType == "coaching" || details.activityType == "tournament") {
      let type = details.activityType;
      let data = {
         title: type || "",
         cssStyle: "col-span-1 justify-center ",
         icon: details.activityType == "tournament" ? "Tournament" : ""
      };
      activityTag.push(data);
   }
   if (details?.gameInstructions?.length) {
      let data = {
         title: details?.gameInstructions.replace(new RegExp("\r?\n", "g"), "<br />") || "",
         cssStyle: "col-span-2 justify-start "
      };
      activityTag.push(data);
   }

   return activityTag;
};

export const getPlayersJoined = (allUser, playingList) => {
   let joyneeArr = [];

   allUser.forEach(e1 =>
      playingList.forEach(e2 => {
         if (e1.id === e2.userId) {
            joyneeArr.push(e1);
         }
      })
   );
   return joyneeArr;
};

export const detectPlatform = (dispatch, sharePage, textUpdate = false, clickLocation, isMobile = false) => {
   const userAgent = navigator.userAgent.toLowerCase();

   const GOOGLE_PLAY_LINK = isMobile ?
      'https://play.google.com/store/apps/details?id=com.techmash.playo&utm_source=playo-mobile-website&utm_medium=mobile-website&utm_campaign=download-the-app' :
      'https://play.google.com/store/apps/details?id=com.techmash.playo&utm_source=playo-website&utm_medium=desktop&utm_campaign=get-the-app';

   const APPLE_STORE_LINK = isMobile ?
      'https://itunes.apple.com/us/app/playo-local-sports-community/id1018786950?ls=1&mt=8&utm_source=playo-mobile-website&utm_medium=mobile-website&utm_campaign=app-download' :
      'https://itunes.apple.com/us/app/playo-local-sports-community/id1018786950?ls=1&mt=8&utm_source=playo-website&utm_medium=desktop-website&utm_campaign=app-download';

   if (userAgent.includes("android")) {
      window.pagesense = window.pagesense || [];
      window.pagesense.push(["trackEvent", "Popup event"]);
      window.dataLayer.push({
         event: "redirect_to_playstore",
         buttonName: "download_cta_playstore"
      });
      window.open(
         GOOGLE_PLAY_LINK,
         "_blank"
      );
   } else if (
      userAgent.includes("iphone") ||
      userAgent.includes("ipad") ||
      userAgent.includes("ipod")
   ) {
      window.pagesense = window.pagesense || [];
      window.pagesense.push(["trackEvent", "Popup event"]);
      window.dataLayer.push({
         event: "redirect_to_appstore",
         buttonName: "download_cta_appstore"
      });
      window.open(
         APPLE_STORE_LINK,
         "_blank"
      );
   } else {
      window.dataLayer.push({
         event: "app_download_modal",
         buttonName: clickLocation
      });
      window.pagesense = window.pagesense || [];
      window.pagesense.push(["trackEvent", "Popup event"]);
      dispatch({
         type: "DOWNLOAD_MODAL",
         payload: { open: true, sharePage: sharePage, textUpdate: textUpdate }
      });
   }
};

export function checkEmail(email) {
   // Regular expression for a basic email validation
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
}

export function generateFilenameWithTime() {
   const date = new Date();
   const timestamp = date.getTime(); // Get timestamp in milliseconds
   // Construct the filename
   const filename = `${timestamp}.jpg`;

   return filename;
}

export function setLocationInLocalStorage(city, lat, lng) {
   // Replace spaces with "-" and convert to lowercase
   // console.log({ city, lat, lng });
   const formattedCity = city.split(" ").join("-").split(",")[0];
   // console.log({ formattedCity });
   // Create an object with the location information
   const locationData = {
      city: formattedCity.toLowerCase(),
      lat: lat,
      lng: lng
   };

   // Convert the location data to a JSON string
   const locationString = JSON.stringify(locationData);

   // Set the location data in the local storage
   localStorage.setItem("userLocation", locationString);
}

export function getLocationFromLocalStorage() {
   // Check if we are running on the client side (browser)
   if (typeof window !== "undefined") {
      // Get the location data JSON string from local storage
      const locationString = localStorage.getItem("userLocation");

      // Check if the data exists in local storage
      if (locationString) {
         // Parse the JSON string to get the location object
         const locationData = JSON.parse(locationString);
         return locationData;
      }
   }

   // If we are not in the browser or no data is found, return null
   return null;
}

export function truncateString(str, limit) {
   if (str?.length <= limit) {
      return str;
   } else {
      return str?.substring(0, limit) + "...";
   }
}
